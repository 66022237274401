.article-card__image {
  // position: absolute;
  top: 0;
  // right: 0;
  // bottom: 0;
  left: 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  // max-height: 100vh;
  // max-width: 90vw;
  // width: 100vw;
  height: 500px;
  // height: auto;
}

@import-normalize; /* bring in normalize.css styles */

:root {
  --blackVal: 12, 12, 12;
  --whiteVal: 255, 255, 255;
  --black: rgba(var(--blackVal), 1);
  --white: rgba(var(--whiteVal), 1);
  --gray: #bbb;
  --header-height: 70px;

  width: 100vw;
}

html {
  font-size: 13px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  html {
    font-size: 8px;
  }
}

* {
  // transition: width 0.4s ease-in;
  // transition: color 0.4s;
  // transition: background-color 0.4s;
}

@font-face {
  font-family: "Druk Text Wide";
  src: url(./assets/fonts/DrukTextWide-Medium.woff2) format("woff2"),
    url(./assets/fonts/DrukTextWide-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.capitalize {
  text-transform: capitalize;
}

.ch-70 {
  max-width: 70ch;
}

.h-100 {
  height: 100%;
}

.fs-2rem {
  font-size: 2rem;
}

.fs-1rem {
  font-size: 1.8rem;
}

.lh-md {
  line-height: 2.2rem;
}

.lh-lg {
  line-height: 3rem;
}

.block-font {
  // font-family: "DrukTextWide-Medium";
  font-family: "Druk Text Wide";
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.hover-bold {
  &:hover {
    font-weight: bold;
  }
}

.fade-in {
  // transition: opacity .2s ease-in;
  animation: fade-in 0.7s ease-in;
}

@keyframes fade-in {
  /* You could think of as "step 1" */
  0% {
    opacity: 0;
  }
  /* You could think of as "step 2" */
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
  // transition: background-color 0s;
  color: var(--white);
}

body::-webkit-scrollbar {
  width: 0 !important;
}

#app__container {
  /* width: 100vw; */
  /* height: 100vh; */
  min-height: 100vh;
  padding-top: var(--header-height);
  box-sizing: border-box;

  // overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.header__container {
  background: -webkit-linear-gradient(
    bottom,
    rgba(var(--blackVal), 0.8) 0%,
    rgba(var(--blackVal), 0.8) 20%
  );
  backdrop-filter: blur(2px);
  // border-bottom: 1px solid var(--white);
}

.border__container {
  user-select: none;
  // position: absolute;
  pointer-events: none;
  z-index: 100;
  // width: 100vw;
  width: 100vw;
  // background-color: var(--black);
  height: 95vh;
  top: 1vh;
  margin: auto;
  // left: 20%;
  mix-blend-mode: difference;
}

.about__container {
  // text-align: justify;
  background-blend-mode: difference;
}

#border__img {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.photo-collage__container {
  position: absolute;
  pointer-events: none;
  top: 10%;
  left: 50%;
  width: 40vw;
  height: 400px;
  opacity: 0;

  transition: opacity 0.4s ease-in;

  &.active {
    opacity: 0.4;
    background-blend-mode: difference;
  }

  img {
    // width: 100px;
    height: auto;
    object-fit: cover;
    background-blend-mode: difference;
  }
}

.button__container {
  background-color: var(--white);
  border: 1px solid var(--white);
  color: var(--black);

  transition: color 0.2s ease-in;

  &:hover {
    border: 1px solid var(--white);
    background-color: var(--black);
    color: var(--white);
  }
}

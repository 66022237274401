.post__container {
  user-select: none;
  cursor: pointer;
  color: var(--gray);
  transition: color 0.2s ease-in;

  .post__index {
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    text-transform: lowercase;
    // transform: rotateX(90deg);
  }

  &:hover {
    color: var(--white);

    .post__title {
      // font-style: italic;
    }
  }
}

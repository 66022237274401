.form__container {
  input {
    // font-style: italic;
    font-size: 3vmin;
    color: var(--white);
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--white);

    &:focus {
      outline: none;
    }
  }

  input[type="submit"] {
    cursor: pointer;
    border: none;
  }

  input {
    border-radius: 0;
  }

  input[type="search"] {
    -webkit-appearance: none;
  }
}

.collection__container {
  transition: color 0.2s ease-in;
  transition: background-color 0.2s ease-in;

  height: fit-content;

  min-height: 120px;
  max-height: 400px;

  .collection__thumbnail {
    object-fit: cover;
  }

  &:hover {
    background-color: var(--white);
    color: var(--black);
    cursor: pointer;
  }
}
